<template>
  <div
    class="search-new-artists mb-8"
    id="search_new_artists"
    v-loading.fullscreen.lock="loading"
  >
    <div class="search-artists-left-bar me-10">
      <div class="card shadow-card search-artist-card">
        <div class="card-body">
          <div class="card-head">
            <span class="heading-sideBar"> Search New Artists</span>
            <hr />
          </div>
          <div class="slider-filters">
            <div class="card-sub-headings d-flex align-items-center">
              <div class="sub-headings-sidebar m-lg-2">Genres</div>
            </div>
            <div class="genre-select">
              <el-select
                class="form-select-solid"
                placeholder="Select Genre"
                v-model="selectedGenre"
                @change="valuesChanged"
                filterable
              >
                <el-option
                  v-for="(genre, i) in genres"
                  :key="i"
                  :value="genre.id"
                  :label="genre.name"
                  >{{ genre.name }}
                </el-option>
              </el-select>
            </div>
            <hr />
            <div class="card-sub-headings d-flex align-items-center">
              <div class="el-image icon stats-social-icon pb-1">
                <img
                  class="el-image__inner"
                  src="/media/svg/social-logos/spotify.svg"
                  alt=""
                />
              </div>
              <div class="sub-headings-sidebar m-lg-2">Monthly Listeners</div>
            </div>
            <div class="monthly-listeners-slider">
              <el-slider
                v-model="monthlyListeners"
                :min="monthlyListenersMin"
                :max="monthlyListenersMax"
                @change="valuesChanged"
                range
              />
              <div
                class="
                  pt-2
                  d-flex
                  justify-content-around
                  theme-v2-color
                  align-items-center
                "
              >
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    v-model="monthlyListenersNum1"
                    :min="monthlyListenersMin"
                    :max="monthlyListenersMax"
                    @change="
                      monthlyListeners = [
                        monthlyListenersNum1,
                        monthlyListenersNum2,
                      ]
                    "
                  />
                </div>
                <div class="fw-boldest ps-4 pe-4">-</div>
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    v-model="monthlyListenersNum2"
                    :min="monthlyListenersMin"
                    @change="
                      monthlyListeners = [
                        monthlyListenersNum1,
                        monthlyListenersNum2,
                      ]
                    "
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="card-sub-headings d-flex align-items-center">
              <div class="el-image icon stats-social-icon pb-1">
                <img
                  class="el-image__inner"
                  src="/media/svg/social-logos/spotify.svg"
                  alt=""
                />
              </div>
              <div class="sub-headings-sidebar m-lg-2">Followers</div>
            </div>
            <div class="followers-slider">
              <el-slider
                v-model="followers"
                range
                :min="followersMin"
                :max="followersMax"
                @change="valuesChanged"
              />
              <div
                class="
                  pt-2
                  d-flex
                  justify-content-around
                  theme-v2-color
                  align-items-center
                "
              >
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="followersMin"
                    :max="followersMax"
                    v-model="followersNum1"
                    @change="followers = [followersNum1, followersNum2]"
                  />
                </div>
                <div class="fw-boldest ps-4 pe-4">-</div>
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="followersMin"
                    :max="followersMax"
                    v-model="followersNum2"
                    @change="followers = [followersNum1, followersNum2]"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="slider-filters">
              <div
                class="
                  card-sub-headings
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <div class="sub-headings-sidebar m-lg-2">
                  Additional Filters
                </div>
                <div
                  class="cursor-pointer"
                  title="Click to Add Filter"
                  @click="addFilters"
                  v-if="addedFilters.length === 0"
                >
                  <el-icon color="#009EF7" size="20">
                    <CirclePlus />
                  </el-icon>
                </div>
              </div>
              <div v-for="(addedFilter, i) in addedFilters" :key="i">
                <div class="filter-select mt-2">
                  <div class="d-flex align-items-baseline">
                    <div style="width: -webkit-fill-available">
                      <el-select
                        class="form-select-solid"
                        placeholder="Select Filter"
                        v-model="addedFilter.value"
                        filterable
                      >
                        <el-option
                          v-for="(filter, i2) in additionalFilters"
                          :key="i2"
                          :value="filter"
                          :label="filter.name"
                          >{{ filter.name }}
                        </el-option>
                      </el-select>
                    </div>
                    <div
                      class="cursor-pointer ms-2"
                      title="Click to Remove Filter"
                      @click="removeFilter(i)"
                    >
                      <el-icon color="red" size="20">
                        <Remove />
                      </el-icon>
                    </div>
                  </div>

                  <div
                    class="filters-slider my-4"
                    v-if="Object.keys(addedFilter).length"
                  >
                    <div v-if="addedFilter.value.type === 'array'">
                      <el-slider
                        v-model="addedFilter.value.value"
                        range
                        :min="addedFilter.value.min"
                        :max="addedFilter.value.max"
                        @change="changeFilterValue(addedFilter, 1, i)"
                      />
                      <div
                        class="
                          pt-2
                          d-flex
                          justify-content-around
                          theme-v2-color
                          align-items-center
                        "
                      >
                        <div class="range-values">
                          <input
                            type="number"
                            class="form-control filter-inputs"
                            :min="addedFilter.value.min"
                            :max="addedFilter.value.max"
                            v-model="addedFilter.value.num1"
                            @change="changeFilterValue(addedFilter, 2, i)"
                          />
                        </div>
                        <div class="fw-boldest ps-4 pe-4">-</div>
                        <div class="range-values">
                          <input
                            type="number"
                            class="form-control filter-inputs"
                            :min="addedFilter.value.min"
                            :max="addedFilter.value.max"
                            v-model="addedFilter.value.num2"
                            @change="changeFilterValue(addedFilter, 2, i)"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        addedFilter.value.type === 'string' ||
                        addedFilter.value.type === 'boolean'
                      "
                    >
                      <el-select
                        class="form-select-solid"
                        placeholder="Select Value"
                        v-model="addedFilter.value.value"
                        filterable
                      >
                        <el-option
                          v-for="(item, index2) in listByFilter(
                            addedFilter.value.key
                          )"
                          :key="index2"
                          :value="item"
                          :label="item"
                          >{{ item }}
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  cursor-pointer
                  d-flex
                  justify-content-center
                  mt-3
                  p-4
                  fw-bolder
                "
                v-if="addedFilters.length"
                style="background: #dee4ee; border-radius: 4px"
                @click="addFilters"
              >
                + Add More Filters
              </div>
              <div
                class="
                  cursor-pointer
                  d-flex
                  justify-content-center
                  mt-4
                  p-3
                  fw-bolder
                "
                @click="getArtistsByFilters"
                style="background: #009ef7; border-radius: 4px; color: #ffffff"
              >
                Search
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-new-artist-main-content row">
      <CardToolbar
        v-loading="tableLoading"
        :show-header="true"
        :show-toolbar-buttons="true"
        :fontStyle="true"
        :padding="false"
        :shadow-class="true"
        :margin="true"
        :title="''"
      >
        <template v-slot:toolbar>
          <el-checkbox
            :disabled="tableData.length === 0"
            v-model="allSelected"
            @change="selectAllClicked"
            label="Select all on page"
            size="large"
          ></el-checkbox>
          <el-checkbox
            v-model="hideExistingArtists"
            :disabled="tableData.length === 0"
            @change="hideArtists"
            label="Hide existing artists"
            size="large"
          ></el-checkbox>
          <CardHeaderButtonAI
            @click="scrapArtists()"
            solid
            :disabled="selectedArtists.length === 0"
            class="ms-3"
          >
            Scrap Artists
          </CardHeaderButtonAI>
        </template>
        <template v-slot:body>
          <el-table
            @sort-change="sortResults"
            :data="tableData"
            :fit="true"
            size="medium"
            tooltip-effect="light"
            stripe
            style="width: 100%"
            header-cell-class-name="table-header-text"
            :row-class-name="tableRowClassName"
            :cell-class-name="tableCellClassName"
          >
            <el-table-column
              :key="checkBoxKey"
              fixed
              width="100"
              label="Select"
              align="left"
            >
              <template #default="scope">
                <el-checkbox
                  :model-value="
                    selectedArtists.includes(scope.row.chartmetricID)
                  "
                  @change="
                    checkBoxValueChanged($event, scope.row.chartmetricID)
                  "
                  label=""
                  size="large"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Name"
              prop="artistName"
              align="left"
              width="200"
            >
              <template #default="scope">
                <p
                  class="truncate"
                  data-tag="p"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    scope.row.artistName ? scope.row.artistName : 'N/A'
                  "
                >
                  {{ scope.row.artistName ? scope.row.artistName : "N/A" }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="Monthly Listeners"
              prop="monthlyListeners"
              width="180"
              align="right"
              sortable="custom"
            >
              <template #default="scope">
                {{
                  scope.row.monthlyListeners
                    ? formatNumberIntoHumanizeForm(scope.row.monthlyListeners)
                    : "N/A"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="Followers"
              prop="spotifyFollowers"
              width="180"
              align="right"
              sortable="custom"
            >
              <template #default="scope">
                {{
                  scope.row.spotifyFollowers
                    ? formatNumberIntoHumanizeForm(scope.row.spotifyFollowers)
                    : "N/A"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="Artist Existence"
              prop="isArtistSaved"
              width="180"
              align="right"
              sortable="custom"
            >
              <template #default="scope">
                <span
                  :class="[
                    'svg-icon svg-icon-checkmark',
                    scope.row.isArtistSaved ? 'svg-icon-primary' : '',
                  ]"
                  :title="
                    scope.row.isArtistSaved
                      ? 'Artist exist in DB'
                      : 'Artist not exist in DB'
                  "
                >
                  <inline-svg src="/media/icons/duotune/general/gen026.svg" />
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="genres"
              align="left"
              label="Genres"
              width="350"
            >
              <template #default="scope">
                {{ scope.row.genres }}
              </template>
            </el-table-column>
          </el-table>
          <div
            v-if="totalRecords > limit"
            @click="showMoreArtists()"
            class="
              cursor-pointer
              d-flex
              justify-content-center
              mt-4
              mb-4
              p-2
              fw-bolder
              theme-v2-color
            "
            style="background: #dee4ee; border-radius: 4px"
          >
            + Show More
          </div>
        </template>
      </CardToolbar>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import {
  formatNumberIntoHumanizeForm,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import { sortArray } from "@/aiBetaBench/helpers";
import { getLiveGenresFromChartmetric } from "@/api/genreResult";
import {
  saveSearchArtistsByChartmetricIDS,
  searchArtistByFilters,
} from "@/api/mongoDB";
import CardHeaderButtonAI from "@/aiBetaBench/components/CardHeaderButtonAI.vue";
import { ElNotification } from "element-plus";
import { CirclePlus, Remove } from "@element-plus/icons-vue";

export default {
  name: "SearchNewArtistsV2",
  methods: {
    formatNumberIntoHumanizeForm,
    tableCellClassName,
    tableRowClassName,
  },
  components: { Remove, CirclePlus, CardHeaderButtonAI, CardToolbar },
  setup() {
    const loading = ref(false);
    const tableLoading = ref(false);
    const monthlyListeners = ref([1000000, 10000000]);
    const monthlyListenersMin = ref(0);
    const monthlyListenersMax = ref(10000000);
    const monthlyListenersNum1 = ref(1000000);
    const monthlyListenersNum2 = ref(10000000);
    const followers = ref([10000, 10000000]);
    const followersMin = ref(0);
    const followersMax = ref(10000000);
    const followersNum1 = ref(10000);
    const followersNum2 = ref(10000000);
    const tableData = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const selectedArtists = ref([]);
    const checkBoxKey = ref(0);
    const allSelected = ref(false);
    const genres = ref([]);
    const selectedGenre = ref(null);
    const limit = ref(100);
    const searchArtists = ref([]);
    const totalRecords = ref(0);
    const hideExistingArtists = ref(false);
    const currentFilter = ref(null);
    const filterCount = ref(0);
    const additionalFilters = ref([
      {
        name: "Sort Column",
        key: "sortColumn",
        type: "string",
        value: "cm_artist_rank",
      },
      {
        name: "Band",
        key: "band",
        type: "boolean",
        value: "Choose Value",
      },
      {
        name: "Sort Order Desc",
        key: "sortOrderDesc",
        type: "boolean",
        value: "Select Value",
      },
      {
        name: "Spotify Popularity",
        key: "sp_p",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Spotify Listeners/Fans ratio",
        key: "sp_ratio",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Spotify Fans/Listeners ratio",
        key: "sp_fl_ratio",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Deezer Fans",
        key: "dz_fans",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Tiktok Followers",
        key: "tt_f",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Tiktok Likes",
        key: "tt_l",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Facebook Followers",
        key: "fb_f",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Facebook Likes",
        key: "fb_l",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Facebook Talks",
        key: "fb_t",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Twitter Followers",
        key: "tw_f",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Twitter Retweets",
        key: "tw_r",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Instagram Popularity",
        key: "ig_f",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Youtube Channel Views",
        key: "ytc_v",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Youtube Daily Videos Views",
        key: "ytd_vv",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Youtube Monthly Videos Views",
        key: "ytm_vv",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Youtube Channel Subscribers",
        key: "ytc_s",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Wikipedia Views",
        key: "wp_v",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Soundcloud Followers",
        key: "sc_f",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Bandsintown Followers",
        key: "bit_f",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "CPP(Ranking)",
        key: "cpp",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Twitch Followers",
        key: "t_f",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Twitch Views",
        key: "t_v",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Twitch Weekly Views Hours",
        key: "t_wvh",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Twitch Monthly Views Hours",
        key: "t_mvh",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Pandora Monthly Listeners",
        key: "p_ml",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Pandora Streams",
        key: "p_s",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Pandora Lifetime Stations",
        key: "p_ls",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Pandora Lifetime Streams",
        key: "p_lsr",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Boomplay Comments",
        key: "bp_c",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Boomplay Favorites",
        key: "bp_f",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Boomplay Plays",
        key: "bp_p",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Boomplay Shares",
        key: "bp_s",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Career Stage",
        key: "career_stage",
        type: "string",
        value: "undiscovered",
      },
      {
        name: "Career Stage Scores",
        key: "career_stage_score",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Career Trend",
        key: "career_trend",
        type: "string",
        value: "decline",
      },
      {
        name: "Career Trend Scores",
        key: "career_trend_score",
        type: "array",
        min: 0,
        max: 10000000,
        num1: 0,
        num2: 10000000,
        value: [0, 10000000],
      },
      {
        name: "Pronoun",
        key: "pronoun",
        type: "string",
        value: "any",
      },
    ]);

    const booleanArray = ref([true, false]);

    const sortColumnArray = ref([
      "boomplay_comments",
      "boomplay_favorites",
      "boomplay_plays",
      "boomplay_ranking_current",
      "boomplay_shares",
      "bs_followers",
      "cm_artist_rank",
      "deezer_fans",
      "facebook_followers",
      "fs_likes",
      "fs_talks",
      "ins_followers",
      "pandora_lifetime_stations_added",
      "pandora_lifetime_streams",
      "pandora_listeners_28_day",
      "pandora_stations_added",
      "rank_eg",
      "rank_fb",
      "sp_followers",
      "sp_followers_to_listeners_ratio",
      "sp_listeners_to_followers_ratio",
      "sp_monthly_listeners",
      "sp_playlist_total_reach",
      "sp_popularity",
      "soundcloud_followers",
      "tiktok_followers",
      "tiktok_likes",
      "tiktok_top_video_views",
      "tiktok_track_posts",
      "ts_followers",
      "ts_retweets",
      "twitch_followers",
      "twitch_views",
      "twitch_monthly_viewer_hours",
      "twitch_weekly_viewer_hours",
      "ws_views",
      "ycs_subscribers",
      "ycs_views",
      "youtube_daily_video_views",
      "youtube_monthly_video_views",
      "line_music_likes",
      "line_music_artist_likes",
      "line_music_plays",
      "line_music_mv_plays",
      "melon_artist_fans",
      "melon_likes",
      "melon_video_likes",
      "melon_video_views",
    ]);

    const careerScoreArray = ref([
      "undiscovered",
      "developing",
      "mid-level",
      "mainstream",
      "superstar",
      "legendary",
    ]);
    const careerTrendArray = ref([
      "decline",
      "gradual decline",
      "steady",
      "growth",
      "explosive growth",
    ]);

    const pronounArray = ref([
      "any",
      "he/him",
      "notSpecified",
      "others",
      "she/her",
      "they/them",
    ]);

    const addedFilters = ref([]);

    onMounted(() => {
      getGenres();
      // getArtistsByFilters();
    });

    const listByFilter = (key) => {
      if (key === "career_stage") {
        return careerScoreArray.value;
      } else if (key === "career_trend") {
        return careerTrendArray.value;
      } else if (key === "pronoun") {
        return pronounArray.value;
      } else if (key === "band" || key === "sortOrderDesc") {
        return booleanArray.value;
      }
      return sortColumnArray.value;
    };

    const getGenres = async () => {
      try {
        loading.value = true;
        const { data } = await getLiveGenresFromChartmetric();
        genres.value = data;
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const getArtistsByFilters = async () => {
      try {
        tableLoading.value = true;
        hideExistingArtists.value = false;
        selectedArtists.value = [];
        allSelected.value = false;
        const payload = {
          minListeners: monthlyListeners.value[0],
          maxListeners: monthlyListeners.value[1],
          minFollowers: followers.value[0],
          maxFollowers: followers.value[1],
          genreID: selectedGenre.value,
          limit: limit.value,
        };

        if (addedFilters.value.length) {
          let url = "";
          for (const filter of addedFilters.value) {
            url += `&${filter.value.key}=${
              filter.value.type === "array"
                ? "[" + filter.value.value + "]"
                : filter.value.value
            }`;
          }
          payload["additionalFilters"] = url;
        }
        const { data } = await searchArtistByFilters(payload);
        searchArtists.value = data.tableDataForArtistSearch
          ? data.tableDataForArtistSearch
          : [];
        totalRecords.value = data.totalRecords ? data.totalRecords : 0;
        tableData.value = searchArtists.value;
      } catch (e) {
        console.log(e);
      } finally {
        tableLoading.value = false;
      }
    };

    const valuesChanged = () => {
      monthlyListenersNum1.value = monthlyListeners.value[0];
      monthlyListenersNum2.value = monthlyListeners.value[1];
      followersNum1.value = followers.value[0];
      followersNum2.value = followers.value[1];
      // getArtistsByFilters();
    };

    const showMoreArtists = () => {
      limit.value += 100;
      getArtistsByFilters();
    };

    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableData.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableData.value))
        );
      }
    };

    const checkBoxValueChanged = (event, id) => {
      allSelected.value = false;
      if (event && !selectedArtists.value.includes(id)) {
        selectedArtists.value.push(id);
      } else if (!event) {
        const index = selectedArtists.value.indexOf(id);
        if (index > -1) {
          selectedArtists.value.splice(index, 1);
        }
      }
    };

    const selectAllClicked = (value) => {
      if (value) {
        selectedArtists.value = tableData.value.map(
          (object) => object.chartmetricID
        );
      } else {
        selectedArtists.value = [];
      }
    };

    const hideArtists = () => {
      tableLoading.value = true;
      if (hideExistingArtists.value) {
        tableData.value = [];
        tableData.value = searchArtists.value.filter((artist) => {
          return !artist.isArtistSaved;
        });
      } else {
        tableData.value = searchArtists.value;
      }

      tableLoading.value = false;
    };

    const scrapArtists = () => {
      try {
        saveSearchArtistsByChartmetricIDS({
          chartmetricIDs: selectedArtists.value,
        });
        selectedArtists.value = [];
        ElNotification({
          title: "Scrapping Started",
          message: "This will take some time",
          type: "success",
        });
      } catch (e) {
        console.log(e);
      }
    };

    const changeFilterValue = (filter, type, i) => {
      if (type === 1) {
        filter.value.num1 = filter.value.value[0];
        filter.value.num2 = filter.value.value[1];
      } else if (type === 2) {
        filter.value.value = [filter.value.num1, filter.value.num2];
      }
      addedFilters.value[i] = filter;
    };

    const addFilters = () => {
      addedFilters.value.push({});
    };
    const removeFilter = (index) => {
      addedFilters.value.splice(index, 1);
    };

    return {
      loading,
      tableLoading,
      monthlyListeners,
      monthlyListenersMin,
      monthlyListenersMax,
      monthlyListenersNum1,
      monthlyListenersNum2,
      followers,
      followersMin,
      followersMax,
      followersNum1,
      followersNum2,
      tableData,
      sortBy,
      sortDir,
      selectedArtists,
      checkBoxKey,
      allSelected,
      genres,
      selectedGenre,
      limit,
      totalRecords,
      searchArtists,
      hideExistingArtists,
      additionalFilters,
      currentFilter,
      addedFilters,
      filterCount,
      listByFilter,
      valuesChanged,
      sortResults,
      checkBoxValueChanged,
      selectAllClicked,
      showMoreArtists,
      hideArtists,
      scrapArtists,
      changeFilterValue,
      addFilters,
      removeFilter,
      getArtistsByFilters,
    };
  },
};
</script>
<style scoped lang="scss">
.search-artists-left-bar {
  max-width: 320px;
  float: left;
  position: sticky;
  top: 0;
  transition: 0.8s;

  .search-artist-card {
    .card-body {
      padding: 1rem !important;

      .slider-filters {
        .card-sub-headings {
          font-size: 14px;
          color: #33647f !important;
        }

        .el-slider {
          padding: 0 6px;
        }
      }
    }
  }
}

.search-new-artist-main-content {
  --bs-gutter-x: 0 !important;
}

.filter-inputs {
  border: none;
  background-color: aliceblue;
  padding: 4px 16px !important;
  text-align: center;
  font-weight: 400;
  color: #33647f !important;
  cursor: pointer;
}

.shadow-card:hover {
  box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5) !important;
  transition: all 0.3s ease !important;
}

.form-select-solid {
  width: 100%;
}
</style>
